var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ndr" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "NDR",
          buttonText: "New Order",
          buttonIconClass: "icon-add",
          buttonLink: "new"
        }
      }),
      _c(
        "div",
        { staticClass: "container-fluid position-relative pt-70 pt-md-96" },
        [
          _c("div", { staticClass: "row pb-30" }, [
            _c("div", { staticClass: "col-8 col-md-4" }, [
              _c(
                "ul",
                { staticClass: "filter-list ndr" },
                _vm._l(_vm.tabs, function(tab) {
                  return _c(
                    "li",
                    {
                      key: tab.id,
                      class: [{ active: _vm.selected.id === tab.id }]
                    },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "btn-filter wsnw",
                          on: {
                            click: function($event) {
                              _vm.selected = tab
                              _vm.$router.replace("?tab=" + tab.id)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(tab.title) + " ")]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ]),
          _c(_vm.selected.component, { tag: "component" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }