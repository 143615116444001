<template>
	<div class="ndr">
		<AppTopbar
		title="NDR"
		buttonText="New Order"
		buttonIconClass="icon-add"
		buttonLink="new"
		></AppTopbar>

		<div class="container-fluid position-relative pt-70 pt-md-96">
			<div class="row pb-30">
				<div class="col-8 col-md-4">
					<ul class="filter-list ndr">
						<li v-for="tab in tabs" :key="tab.id" :class="[{ active: selected.id === tab.id }]">
							<b-link @click="selected = tab;$router.replace('?tab='+tab.id)" class="btn-filter wsnw">
								{{ tab.title }}
							</b-link>
						</li>
					</ul>
				</div>
			</div>

			<component :is="selected.component"></component>
		</div>
	</div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";

import NdrActionRequired from "@/components/NdrActionRequired.vue";
import NdrSubmited from "@/components/NdrSubmited.vue";

import NdrNewActionRequired from "@/components/NdrNewActionRequired.vue";
import NdrNewSubmited from "@/components/NdrNewSubmited.vue";
import NdrNewExpired from "@/components/NdrNewExpired.vue";


export default {
	name: "Ndr",
	title: "Shyplite - NDR",
	data() {
		return {
			seller: this.$store.getters.user,
			activeTab: parseInt(this.$route.query.tab) || 0,
			selected: {
				id: 0,
				component: "NdrActionRequired",
				title: "Action Required",
				buttonsVisible: true,
			},
			tabs: [
			{
				id: 0,
				component: "NdrActionRequired",
				title: "Action Required",
				buttonsVisible: true,
			},
			{
				id: 1,
				component: "NdrSubmited",
				title: "Submitted",
				buttonsVisible: false,
			},
			],
		};
	},
	components: {
		AppTopbar,
		NdrActionRequired,
		NdrSubmited,
		NdrNewActionRequired,
		NdrNewSubmited,
		NdrNewExpired
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
	},
	created() {
		this.selected = this.tabs[this.activeTab];
		const sellerID = parseInt(this.seller.id);
		if ((sellerID == 165) || (sellerID == 76330)) {
			this.selected = {
				id: 0,
				component: "NdrNewActionRequired",
				title: "Action Required",
				buttonsVisible: true,
			};
			this.tabs = [
				{
					id: 0,
					component: "NdrNewActionRequired",
					title: "Action Required",
					buttonsVisible: true,
				},
				{
					id: 1,
					component: "NdrNewSubmited",
					title: "Submitted",
					buttonsVisible: false,
				},
				{
					id: 2,
					component: "NdrNewExpired",
					title: "Expired",
					buttonsVisible: false,
				},
			];
		}
	}
};
</script>

<style lang="scss" scoped>
	
.ndr-side-btn {
	@include media-breakpoint-down(sm) {
		display: inline;
		i {
			margin: 0 !important;
			padding: 0 !important;
		}
		span {
			display: none;
		}
	}
}

@include media-breakpoint-only(md) {
	.action-buttons {
		position: revert;
		top: rem(90px);
		right: rem(20px);
		margin: -60px 0px 10px 0px;
	}
}
@include media-breakpoint-down(sm) {
	.action-buttons {
		position: revert;
		z-index: 10;
		top: rem(147px);
		right: rem(20px);
		margin: -60px 0px 10px 0px;
	}
}

</style>