<template>
	<div class="ndr">
		<AppTopbar
			title="Reconciliation"
			buttonText="New Order"
			buttonIconClass="icon-add"
			buttonLink="/new"
		></AppTopbar>

<!-- 		<div class="container-fluid position-relative pt-70 pt-md-96">
			<div class="row pb-30">
				<div class="col-9 col-md-8">
					<ul class="filter-list ndr">
						<li v-for="tab in tabs" :key="tab.id" :class="[{ active: selected.id === tab.id }]">
							<b-link @click="selected = tab; pageTab(tab.id)" class="btn-filter wsnw">
								{{ tab.title }}
							</b-link>
						</li>
					</ul>
				</div>
			</div>

			<component :is="selected.component" :ref="selected.component"></component>
		</div> -->


		<div class="container-fluid position-relative pt-70 pt-md-96">
			<div class="row pb-30">
				<div class="mt-20 col-12 col-md-12 text-center fs28 fw500">
					Reconciliation is disabled.
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import NdrSubmited from "@/components/NdrSubmited.vue";
import AppTopbar from "@/components/layout/AppTopbar.vue";

import ReconActionRequired from "@/views/dispute/ReconActionRequired.vue";
import ReconImages from "@/views/dispute/ReconImages.vue";

export default {
	name: "Reconciliation",
	title: "Shyplite - Reconciliation",
	data() {
		return {
			activeTab: parseInt(this.$route.query.tab) || 0,
			selected: {
				id: 0,
				component: "ReconActionRequired",
				title: "Action Required",
				buttonsVisible: true,
			},
			tabs: [
			{
				id: 0,
				component: "ReconActionRequired",
				title: "Action Required",
				buttonsVisible: true,
			},
			{
				id: 1,
				component: "ReconActionRequired",
				title: "Submitted",
				buttonsVisible: false,
			},
			{
				id: 2,
				component: "ReconActionRequired",
				title: "Accepted",
				buttonsVisible: false,
			},
			{
				id: 3,
				component: "ReconActionRequired",
				title: "Rejected",
				buttonsVisible: false,
			},
			{
				id: 4,
				component: "ReconImages",
				title: "Images",
				buttonsVisible: false,
			},
			],
		};
	},
	components: {
		AppTopbar,
		NdrSubmited,
		ReconActionRequired,
		ReconImages
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		pageTab(id){
			if ( this.$refs.ReconImages == undefined && id !=4 ) {
				this.$refs.ReconActionRequired.routeTab = id
				this.$refs.ReconActionRequired.currentPage = 1
				this.$refs.ReconActionRequired.perPage = 25
				this.$refs.ReconActionRequired.getItems();
			} else {
				this.$router.replace(`?tab=${id}`)
			}
		}
	},
	created() {
		// const tab = this.tabs.find(item => '#'+item.title.toLowerCase() == location.hash.toLowerCase())
		this.selected = this.tabs[this.activeTab]
	}
};
</script>

<style lang="scss" scoped>
.ndr-side-btn {
	@include media-breakpoint-down(sm) {
		display: inline;
		i {
			margin: 0 !important;
			padding: 0 !important;
		}
		span {
			display: none;
		}
	}
}
.action-buttons {
	position: fixed;
	top: rem(94px);
	right: rem(36px);
	@include media-breakpoint-only(md) {
		top: rem(90px);
		right: rem(20px);
	}
	@include media-breakpoint-down(sm) {
		z-index: 10;
		top: rem(86px);
		right: rem(20px);
	}
}
</style>
