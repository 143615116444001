var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid col-lg-8" }, [
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        {
          staticClass:
            "card__title d-flex justify-content-between mobile_reconimg"
        },
        [
          _c("span", { staticClass: "fs20 lh24 fw500" }, [
            _vm._v(" Upload Reconciliation Images ")
          ]),
          _c("div", { staticClass: "text-right" }, [
            _c(
              "span",
              { staticClass: "fs18 lh24 fw500" },
              [
                _vm._v("Total Images : "),
                _vm.loadingImages
                  ? _c("b-spinner")
                  : _c("span", [_vm._v(_vm._s(_vm.imageCount))])
              ],
              1
            )
          ])
        ]
      ),
      _c("div", { staticClass: "card__content" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center pl-100 mobile_reconimg_content"
          },
          [
            _c("div", { staticClass: "col-md-3 pb-md-0 pb-32" }, [
              _c(
                "div",
                {
                  staticClass:
                    "rounded border text-center d-inline-block w-sm-100"
                },
                [
                  _vm.reconImages.length
                    ? _c("img", {
                        staticClass: "p-44",
                        attrs: {
                          src: require("@/assets/svg/document-uploaded.svg")
                        }
                      })
                    : _c("img", {
                        staticClass: "p-44",
                        attrs: { src: require("@/assets/svg/document.svg") }
                      })
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "col-md-7 pt-20" },
              [
                _c("b-form-file", {
                  ref: "face-file-input",
                  staticClass: "d-none",
                  attrs: {
                    plain: "",
                    multiple: "",
                    accept: ".jpeg, .jpg, .png"
                  },
                  on: {
                    input: function($event) {
                      return _vm.selectImages(_vm.reconImages)
                    }
                  },
                  model: {
                    value: _vm.reconImages,
                    callback: function($$v) {
                      _vm.reconImages = $$v
                    },
                    expression: "reconImages"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "w-sm-100 mr-0 mr-md-8",
                        attrs: { variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.popInput("face-file-input")
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "mr-5 selectImage",
                          attrs: { src: require("@/assets/svg/image.svg") }
                        }),
                        _vm._v(" Choose Files ")
                      ]
                    ),
                    _vm.reconImages.length
                      ? _c(
                          "b-button",
                          {
                            staticClass: "w-sm-100 mt-8 mt-md-0",
                            attrs: { variant: "red" },
                            on: {
                              click: function($event) {
                                _vm.reconImages = []
                              }
                            }
                          },
                          [_c("i", { staticClass: "icon-close-variant" })]
                        )
                      : _vm._e(),
                    _vm.reconImages.length
                      ? _c("div", { staticClass: "col row pt-10" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.reconImages ? _vm.reconImages.length : ""
                              ) +
                              " File Uploaded "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._m(0)
              ],
              1
            )
          ]
        ),
        _vm._m(1),
        _c(
          "div",
          {
            staticClass:
              "row pl-160 pl-md-160 pt-md-30 pt-30 mobile_reconimg_btn"
          },
          [
            _c(
              "b-button",
              {
                attrs: {
                  variant: "primary",
                  disabled: _vm.reconImages.length < 1
                },
                on: {
                  click: function($event) {
                    return _vm.submitReconImages(_vm.reconImages)
                  }
                }
              },
              [
                _vm.submitting ? _c("b-spinner") : _c("span"),
                _vm._v(" Submit ")
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col mt-10 text-gray-600" }, [
      _c("strong", [_vm._v(" Supported formats are JPG, PNG, JPEG. ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex pl-160 pl-md-160 pt-40 pt-md-40 mobile_reconimg_note"
      },
      [
        _c("strong", { staticClass: "fs16" }, [_vm._v("NOTE :")]),
        _c("ul", { staticClass: "info-card sm ml-10" }, [
          _c("li", [
            _c("span", [
              _vm._v(
                " Multiple images can be selected. Max 100 up to 20MB size. "
              )
            ])
          ]),
          _c("li", [
            _c("span", [
              _vm._v(" Please upload images with SKU/Product name. ")
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }