<template>
	<div class="container-fluid col-lg-8">
		<div class="card">
			<div class="card__title d-flex justify-content-between mobile_reconimg">
				<span class="fs20 lh24 fw500">
                  Upload Reconciliation Images
              </span>
				<div class="text-right">
						
					<span class="fs18 lh24 fw500">Total Images : 
						<b-spinner v-if="loadingImages"></b-spinner>
						<span v-else>{{imageCount}}</span>
					</span> 
				</div>
			</div>

			<div class="card__content">
				<div class="d-flex justify-content-center pl-100 mobile_reconimg_content">
        			<div class="col-md-3 pb-md-0 pb-32">
        				<div class="rounded border text-center d-inline-block w-sm-100">
	        				<img
	        				v-if="reconImages.length"
	        				src="@/assets/svg/document-uploaded.svg"
	        				class="p-44"
	        				/>
	        				<img v-else src="@/assets/svg/document.svg" class="p-44" />
        				</div>
        			</div>
	        		<div class="col-md-7 pt-20">
	        			<b-form-file
	        			plain
	        			multiple
	        			accept=".jpeg, .jpg, .png"
	        			ref="face-file-input"
	        			v-model="reconImages"
	        			class="d-none"
	        			@input="selectImages(reconImages)"
	        			></b-form-file>
	        			<div class="col-md-12">
	        				<b-button
			        			@click="popInput('face-file-input')"
			        			variant="primary"
			        			class="w-sm-100 mr-0 mr-md-8"
			        			>
			        			<img class="mr-5 selectImage" src="@/assets/svg/image.svg">
			        			Choose Files
				        		</b-button>
				        		<b-button
				        		v-if="reconImages.length"
				        		@click="reconImages = []"
				        		variant="red"
				        		class="w-sm-100 mt-8 mt-md-0"
				        		>
				        		<i class="icon-close-variant"></i>
				        	</b-button>
				        	<div class="col row pt-10" v-if="reconImages.length">
			        			{{reconImages ? reconImages.length : ''}} File Uploaded
			        		</div>
	        			</div>
	        			<div class="col mt-10 text-gray-600">
	        				<strong> Supported formats are JPG, PNG, JPEG. </strong> 
	        			</div>	
				    </div>
				</div>

				<div class="d-flex pl-160 pl-md-160 pt-40 pt-md-40 mobile_reconimg_note">
					<strong class="fs16">NOTE :</strong>
					<ul class="info-card sm ml-10" >
				      <li>
				        <span>
				          Multiple images can be selected. Max 100 up to 20MB size.
				      </span>
				    </li>
				    <li>
				        <span>
				          Please upload images with SKU/Product name.
				      </span>
				    </li>
				    </ul>
				</div>

				<div class="row pl-160 pl-md-160 pt-md-30 pt-30 mobile_reconimg_btn">
					<b-button variant="primary" :disabled="reconImages.length < 1" @click="submitReconImages(reconImages)">
						<b-spinner  v-if="submitting"></b-spinner>
						<span v-else></span>
						Submit
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>


<script>

export default {
  name: "ReconImages",
  data() {
    return {
    	reconImages:[],
    	imageCount: 0,
    	submitting:false,
    	loadingImages:false
  };
},
created() {
	this.getImageCount()
},
computed:{

},
methods: {
	popInput(value) {
		this.$refs[value].$el.click();
	},
	async getImageCount() {
		this.loadingImages = true
		try {
			const res = await this.axios.get('/reconciliation/images')
			this.imageCount = res.data.totalImages
			this.loadingImages = false
		} catch(e) {
			// statements
			this.loadingImages = false
			console.log(e);
		}
	},
	selectImages(images) {
		if (images.find(item => !(item.name.split('.').pop().includes('png') || item.name.split('.').pop().includes('jpeg') || item.name.split('.').pop().includes('jpg')))) {
			this.popToast('failed','Failed','File format not supported')
			this.reconImages = []
			return
		} else {
			// statement
		}

		let total = 0
		images.map( i=> { total += i.size })
		total  = Math.round((total / 1024))
		if (total > 20480) {
			this.popToast('failed','Failed','Files Size exceeds 20MB!! Please Try Again')
			this.reconImages = []
		} 
	},
	async submitReconImages(images) {
		this.submitting = true
		const obj = {'file':images}
		let fd = new FormData()

		for (const key of Object.keys(obj)) {
        	if (key == 'file') {
        		obj[key].forEach(i => {
		    		fd.append(key, i);
        		});
        	}
        }
		try {
			const res = await this.axios.post('/reconciliation/images',fd)
			if (res.data.status) {
				this.popToast('booked','Success',res.data.message)
				this.submitting = false
				this.imageCount += parseInt(res.data.total)
			} else {
				this.popToast('failed','Failed',"File Upload Unsuccessful!! Pleas Try Again")
				this.submitting = false
				this.reconImages = []
			}
		} catch(e) {
			console.log(e)
			this.popToast('failed','Failed',"File Upload Unsuccessful!! Pleas Try Again")
			this.submitting = false
			this.reconImages = []
		}
	}
}
};

</script>

<style scoped>

.selectImage {
	width: 1.5rem;
	height: 1.5rem;
}

/deep/ .spinner-border {
	height: 1.2rem;
	width: 1.2rem;
	margin-right: unset;
}
</style>