var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ndr-sidebar" },
    [
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "sidebarFilters",
            right: "",
            backdrop: "",
            "backdrop-variant": "dark",
            "sidebar-class": "filter-side",
            "no-header": "",
            shadow: ""
          },
          model: {
            value: _vm.sidebarVisible,
            callback: function($$v) {
              _vm.sidebarVisible = $$v
            },
            expression: "sidebarVisible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column h100vh px-36 pb-32 pt-36" },
            [
              _c(
                "div",
                { staticClass: "header d-flex align-items-center pb-40" },
                [
                  _c("i", {
                    staticClass: "icon-filter text-gray-600 fs24 pr-16"
                  }),
                  _c("h2", { staticClass: "text-gray-900" }, [
                    _vm._v("Filters")
                  ]),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.sidebarFilters",
                          modifiers: { sidebarFilters: true }
                        }
                      ],
                      attrs: { variant: "rounded ml-auto" }
                    },
                    [_c("i", { staticClass: "icon-close-variant" })]
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    { attrs: { "label-for": "type" } },
                    [
                      _c("multiselect", {
                        staticClass: "mb-10",
                        attrs: {
                          id: "type",
                          title: "Carrier",
                          options: _vm.carriers,
                          searchable: true,
                          "show-labels": false,
                          placeholder: "Select Carrier"
                        },
                        on: {
                          input: function($event) {
                            return _vm.addFilter({ carrier: _vm.form.carrier })
                          }
                        },
                        model: {
                          value: _vm.form.carrier,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "carrier", $$v)
                          },
                          expression: "form.carrier"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.$route.query.tab == 1
                    ? _c(
                        "b-form-group",
                        { attrs: { "label-for": "action" } },
                        [
                          _c("multiselect", {
                            staticClass: "mb-10",
                            attrs: {
                              id: "action",
                              title: "Action",
                              options: _vm.actions,
                              searchable: false,
                              "show-labels": false,
                              placeholder: "Select Action"
                            },
                            on: {
                              input: function($event) {
                                return _vm.addFilter({
                                  action: _vm.form.action
                                })
                              }
                            },
                            model: {
                              value: _vm.form.action,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "action", $$v)
                              },
                              expression: "form.action"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    { attrs: { "label-for": "awb" } },
                    [
                      _c("b-input", {
                        staticClass: "mb-10",
                        attrs: {
                          id: "awb",
                          title: "Search by AWB",
                          placeholder: "Type AWB"
                        },
                        on: {
                          input: function($event) {
                            return _vm.addFilter({ awb: _vm.form.awb })
                          }
                        },
                        model: {
                          value: _vm.form.awb,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "awb", $$v)
                          },
                          expression: "form.awb"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-for": "dp" } },
                    [
                      _c("date-picker", {
                        attrs: {
                          "prefix-class": "xmx",
                          id: "dp",
                          range: "",
                          title: "Date",
                          "value-type": "format",
                          format: "YYYY-MM-DD",
                          editable: false,
                          "disabled-date": function(date) {
                            return date > new Date()
                          },
                          placeholder: "Start Date - End Date"
                        },
                        on: {
                          input: function($event) {
                            return _vm.addFilter({ date: _vm.form.date })
                          }
                        },
                        model: {
                          value: _vm.form.date,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "date", $$v)
                          },
                          expression: "form.date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "footer mt-auto text-right pb-32 pt-8" },
                [
                  this.hasFilters
                    ? _c(
                        "b-link",
                        {
                          staticClass: "secondary fw500 mr-20",
                          on: {
                            click: function($event) {
                              return _vm.clearFilters()
                            }
                          }
                        },
                        [_vm._v(" Clear filters ")]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { disabled: !this.hasFilters, variant: "primary" },
                      on: { click: _vm.applyFilter }
                    },
                    [_vm._v(" Apply filter ")]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }