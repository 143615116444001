 <template>
 	<div class="ndr-sidebar">
 		<b-sidebar
 		id="sidebarFilters"
 		right
 		backdrop
 		backdrop-variant="dark"
 		sidebar-class="filter-side"
 		no-header
 		shadow
 		v-model="sidebarVisible"
 		>
 		<div class="d-flex flex-column h100vh px-36 pb-32 pt-36">
 			<div class="header d-flex align-items-center pb-40">
 				<i class="icon-filter text-gray-600 fs24 pr-16"></i>
 				<h2 class="text-gray-900">Filters</h2>
 				<b-button variant="rounded ml-auto" v-b-toggle.sidebarFilters>
 					<i class="icon-close-variant"></i>
 				</b-button>
 			</div>

 			<div>
 				<b-form-group label-for="type">
 					<multiselect
 					id="type"
 					:allow-empty="false"
 					v-model="form.carrier"
 					:options="carriers"
 					:searchable="true"
 					:show-labels="false"
 					@input="addFilter({carrier:form.carrier})"
 					placeholder="Select Carrier"
 					class="mb-10"
 					></multiselect>
 				</b-form-group>
 				<!-- <b-form-group v-if="$route.query.tab==1" label-for="action">
 					<multiselect
 					id="action"
 					v-model="form.action"
 					:options="actions"
 					:searchable="false"
 					:show-labels="false"
 					placeholder="Select Action"
 					@input="addFilter({action: form.action})"
 					class="mb-10"
 					></multiselect>
 				</b-form-group> -->
 				<b-form-group label-for="awb">
 					<b-input
 					id="awb"
 					v-model="form.awb"
 					@input="addFilter({awb:form.awb})"
 					class="mb-10"
 					placeholder="Type AWB"
 					></b-input>
 				</b-form-group>

 				<date-picker
				prefix-class="xmx"
				title="Date"
				v-model="form.date"
				id="dp date"
				range
				value-type="format"
				format="YYYY-MM-DD"
				placeholder="Start Date - End Date"
				:editable="false"
				:disabled-date="(date) => date > new Date()"
				@input="addFilter({date: form.date})"
				></date-picker>
 				<!-- <b-form-group label-for="dp">
 					<date-picker
 					prefix-class="xmx"
 					v-model="form.date"
 					id="dp"
 					range
 					value-type="format"
 					format="YYYY-MM-DD"
 					:editable="false"
 					:disabled-date="(date) => date > new Date()"
 					placeholder="Start Date - End Date"
 					@input="addFilter({date:form.date})"
 					></date-picker>
 				</b-form-group> -->
 				<div v-if="showError" class="text-red fs12">
					<p>Data can be exported for maximum </p>
					<p>30 days.</p>
				</div>
 			</div>

 			<div class="footer mt-auto text-right pb-32 pt-8">
 				<b-link
 				v-if="this.hasFilters"
 				class="secondary fw500 mr-20"
 				@click="clearFilters()"
 				>
 				Clear filters
 			</b-link>
 			<b-button
 			:disabled="!this.hasFilters"
 			variant="primary"
 			@click="applyFilter"
 			>
 			Apply filter
 		</b-button>
 	</div>
 </div>
</b-sidebar>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
	name: "ReconFilterSidebar",
	props: {
		filters: Array,
	},
	data() {
		return {
			date: "",
			sidebarVisible: false,
			selectedFilter: 1,
			modetypes: this.$store.getters.modes.map(item => item.name),
			selectedFilters: this.$store.getters.filters.recon || {},
			carriers: this.$store.getters.carriers.map(item => item.name),
			actions: ["Re-Attempt", "Return"],
			form: {
				carrier:"",
				awb:""
			},
			showError:false
		};
	},
	components: {
		DatePicker,
		Multiselect,
	},
	created() {
		let form = Object.assign({}, this.$store.getters.filters.recon)
		form.date = [this.selectedFilters.dateStart, this.selectedFilters.dateEnd]
		delete form.dateStart
		delete form.dateEnd
		form['carrier'] = form.carrier ? form.carrier : ""
		form['awb'] = form.awb ? form.awb : ""
		this.form = form
	},
	computed: {
		hasFilters() {
			if (Object.keys(this.selectedFilters).length>0) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		applyFilter(event) {
			if(this.selectedFilters.date) {
				this.selectedFilters.dateStart = this.selectedFilters.date[0]
				this.selectedFilters.dateEnd = this.selectedFilters.date[1]
				delete this.selectedFilters.date
			} 
			else if (this.selectedFilters.dateStart && this.selectedFilters.dateEnd) {
				this.selectedFilters.dateStart = this.selectedFilters.dateStart
				this.selectedFilters.dateEnd = this.selectedFilters.dateEnd
			}
		 	else {
				this.selectedFilters.dateStart = ""
				this.selectedFilters.dateEnd = ""
			}  
			if (!this.selectedFilters.carrier) {
				this.selectedFilters.carrier = ""
			}  
			if (!this.selectedFilters.awb) {
				this.selectedFilters.awb = ""
			} 
			this.sidebarVisible = false;
			this.$store.commit('setFilter', {'recon': this.selectedFilters})
			this.$parent.getFilters()
		},


		addFilter(filter) {
			if(Object.values(filter)[0]) {
				this.selectedFilters = {...this.selectedFilters, ...filter};
			}else {
				let temp = this.selectedFilters
				delete temp[Object.keys(filter)[0]]
				this.selectedFilters = Object.assign({}, temp)
			}

			if (this.selectedFilters.date) {
				this.selectedFilters.dateStart = this.selectedFilters.date[0]
				this.selectedFilters.dateEnd = this.selectedFilters.date[1]
				let date1 = new Date(this.selectedFilters.dateStart)
				let date2 = new Date(this.selectedFilters.dateEnd)
				let  diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10); 
				if (diffDays > 30) {
					this.showError = true
					this.$parent.showExport = false
				} else {
					this.showError = false
					this.$parent.showExport = true
				}
			}

			
		},
		clearFilters() {
			let filter = this.form
			this.form = {}
			this.selectedFilters = [];
			this.$store.commit('setFilter', {})
			if(Object.keys(filter).length>0) {
				this.$parent.clearFilters();
			}
			this.showError = false
		},
	},
};
</script>

<style lang="scss">
.ndr-sidebar {
	display: flex;
	align-items: center;
	white-space: nowrap;
	z-index: 10;
	.elem-outer {
		z-index: 10;
	}
	@include media-breakpoint-down(md) {
		.divider {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		position: fixed;
		top: rem(85px);
		right: rem(20px);
		z-index: 10;
	}
	input.multiselect__input{
		height:auto;
		padding:0;
	}
}
</style>
