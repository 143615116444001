var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ndr" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Reconciliation",
          buttonText: "New Order",
          buttonIconClass: "icon-add",
          buttonLink: "/new"
        }
      }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container-fluid position-relative pt-70 pt-md-96" },
      [
        _c("div", { staticClass: "row pb-30" }, [
          _c(
            "div",
            { staticClass: "mt-20 col-12 col-md-12 text-center fs28 fw500" },
            [_vm._v(" Reconciliation is disabled. ")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }