<template>
	<div>
		<div class="d-flex align-items-center justify-content-end action-buttons ndr_action_buttons pt-70">
			<div class="hbreak d-none d-md-inline-flex"></div>
			<b-form-file ref="file" v-model="file" accept=".xlsx, .xls" class="d-none" plain></b-form-file>

			<b-button variant="primary" v-if="items.length>0 && seller.ndrEnabled==0" :disabled="downloading" class="mr-8 mr-md-16" @click="submitForm">
				Submit
			</b-button>

			<b-button :disabled="uploading" v-if="items.length>0 && seller.ndrEnabled==0" @click="popInput" variant="secondary" class="mr-8 mr-md-0">
				<i class="icon-upload fs16"></i>
				<span class="d-none d-md-inline ml-0 ml-md-10">
					Bulk Upload <b-spinner v-if="uploading" class="sm"></b-spinner>
				</span>
			</b-button>

			<b-button variant="secondary" v-if="items.length>0 && seller.ndrEnabled==0" :disabled="downloading" class="mr-8 mr-md-0 ml-md-16" @click="downloadOrders">
				<i class="icon-download fs16"></i>
				<span class="d-none d-md-inline ml-0 ml-md-10">
					Download <b-spinner v-if="downloading" class="sm"></b-spinner>
				</span>
			</b-button>

			<div class="hbreak d-none d-md-inline-flex"></div>
			<div class="divider mx-20 vam d-none d-md-flex"></div>
			<b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900">
				<span>
					Filters
				</span>
				<i class="icon-filter text-gray-600 pl-10"></i>
			</b-button>
		</div>

		<transition name="slide-right">
			<div class="select-bar d-flex ndr" v-if="filterResultsToggle">
				<div class="container-fluid d-flex align-items-center">
					<div class="d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0">
						<div class="d-flex align-items-center">
							<span class="d-none d-md-flex mr-6 text-gray-600 wsnw" v-if="this.selFilters.length != '0'">
								Results for:
							</span>
							<ul class="d-none d-md-flex result-list">
								<li v-for="(selFilter, index) in selFilters" :key="index">
									<b-button class="mx-4 my-1" variant="tag" v-if="index!='filtering'">
										<span v-if="selFilter.hasOwnProperty('name')" class="wsnw">
											{{ selFilter.name }}
										</span>
										<span v-else class="wsnw text-truncate">
											{{ (selFilter.includes('\n') || selFilter.includes(',')) ? 'Multiple': selFilter }}
										</span>
										<i class="icon-close fs6" @click="removeFilter(index)"></i>
									</b-button>
								</li>
							</ul>

							<span class="d-flex d-md-none" v-if="this.selFilters.length != '0'"> {{ this.selFilters.length }} Filters selected.
							</span>
							<span class="d-flex d-md-none" v-else>
								No filters selected.
							</span>
							<b-link @click="clearFilters()" class="ml-24 mr-12 vam">
								<span class="text-gray-900 wsnw">Clear filters</span>
								<i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
							</b-link>
						</div>
						<div class="d-flex align-items-center">
							<b-button variant="secondary" class="wsnw" @click="downloadOrders" :disabled="downloading" v-if="items.length>0 && seller.ndrEnabled==0">
								<i class="icon-download fs16"></i>
								<span class="d-none d-md-inline ml-0 ml-md-10">
									Download
								</span>
							</b-button>
							<b-button variant="secondary" class="text-gray-900 d-none d-md-flex ml-8" v-b-toggle.sidebarFilters>
								<span class="d-none d-md-flex">
									Filters
								</span>
								<i class="icon-filter text-gray-600 pl-0 pl-md-10"></i>
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</transition>

		<div v-if="this.windowWidth > 767">
			<b-table
			:items="items"
			:fields="fields"
			:per-page="perPage"
			:busy="isBusy"
			sticky-header
			no-border-collapse
			@row-clicked="rowClicked"
			class="ndr-table"
			>
			<template v-slot:head(actionBy)="data">
				<span>
					{{ data.field.label }}
					<i class="icon-disclaimer fs12 vam" :id="data.field.label"></i>
					<b-popover
					:target="data.field.label"
					placement="auto"
					title="Popover!"
					triggers="hover focus"
					>
					<span>
						<div style="min-width:200px;color:#828b9d;font-weight:400;">
						<div><b>Shyplite:</b> Action Performed by Shyplite</div><br>
						<div><b>Seller:</b> Action Required by Seller</div>
						</div>
					</span>
					</b-popover>
				</span>
          	</template>
			<template #table-busy>
				<div class="text-center text-danger my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>
			<template v-slot:cell(awb)="data">
				<b-link @click='popToast("booked", "Success", "Successfully copied to clipboard!")' v-clipboard:copy="data.item.awb" v-b-tooltip.hover.right class="cp underline-dashed text-decoration-none text-gray-900" title="Click to copy!">
					{{ data.item.awb }}
				</b-link>
			</template>
			<template v-slot:cell(orderId)="data">
					{{ data.item.orderId }}
			</template>
			<template v-slot:cell(carrierRemark)="data">
					{{ data.item.carrierRemark.remark }}
			</template>
			<template v-slot:cell(lastOFD)="data">
					{{ data.item.lastOFD }}
			</template>
			<template v-slot:cell(customer)="data">
				<span class="wsnw d-block">
					{{ data.item.customer.name.substring(0, 35) }}
				</span>
				<span class="fs12 lh16 text-gray-600">
					{{ data.item.customer.id }}
				</span>
			</template>
			<template v-slot:cell(ndrDate)="data">
				<span class="d-block wsnw">
					{{ data.item.ndrDate.date }}
				</span>
				<span class="fs12 lh16 text-gray-600">
					{{ data.item.ndrDate.time }}
				</span>
			</template>
			<template v-slot:cell(sellerRemark)="data">
				<b-form-input type="text" placeholder="Seller remark" v-model="data.item.sellerRemark.remark" class="small w120px"></b-form-input>
			</template>
			<template v-slot:cell(action)="data"> 
				<multiselect
				id="action"
				v-model="data.item.selected"
				:options="actionOptions"
				:searchable="false"
				:show-labels="false"
				placeholder="- Select -"
				class="h36-select w120px"
				></multiselect>
			</template>
			<template v-slot:cell(status)="data">
				<template>
					<span class="wsnw tag" :class="data.item.status | lowercase">
						{{ data.item.status }}
					</span>
				</template>
			</template>
		</b-table>
	</div>

	<ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
		<li v-for="item in items" :key="item.id">
			<div class="d-flex align-items-center justify-content-between w-100 mb-4">
				<span class="d-block fw500 pb-8 cp">
					Order ID: 
					{{ item.orderId }}
				</span>
				<multiselect
				id="action"
				:value="item.selected"
				:options="actionOptions"
				:searchable="false"
				:show-labels="false"
				placeholder="- Select -"
				class="h36-select"
				></multiselect>
			</div>

			<div class="info">
				<div class="d-flex w-100">
					<span class="carrier-name">
						{{ item.carrier }}
					</span>
				</div>
				<div class="d-flex align-items-center justify-content-between w-100">
					<span class="mb-4">
						{{ item.carrierRemark.remark }}
					</span>
				</div>

				<div class="d-flex align-items-center justify-content-between w-100 mt-8">
					<div class="d-flex flex-column">
						<span>
							{{ item.customer.name }}
						</span>
						<span class="fs13 text-gray-600">
							{{ item.customer.id }}
						</span>
					</div>
					<div class="d-flex flex-column text-right">
						<span>
							{{ item.sellerRemark.remark }}
						</span>
						<span class="fs12 text-gray-600">
							{{ item.sellerRemark.datetime }}
						</span>
					</div>
				</div>
				<span class="mt-8">
	                <strong>AWB: </strong> {{ item.awb }}
	            </span>
				<div class="d-flex align-items-center justify-content-between w-100 mt-8">
					<span class="text-gray-600">
						{{ item.ndrDate.date }} - {{ item.ndrDate.time }}
					</span>
					<div class="price ml-0">
						<span class="bg-box" :class="item.type">
							{{ item.type | uppercase }}
						</span>
						<template>
							<span class="wsnw tag" :class="item.status | lowercase">
								{{ item.status }}
							</span>
						</template>
					</div>
				</div>
			</div>

			<div class="d-flex w-100 align-items-center  justify-content-between">
				<span class="fs12 lh16 text-gray-600">
					{{ item.date }}
				</span>

				<template v-if="item.status == 'Processed' || item.status == 'Error'">
					<span class="tag" :class="item.status | lowercase">
						{{ item.status }}
					</span>
				</template>

				<template v-if="item.status == 'Booked'">
					<b-button variant="primary btn-xs" @click="downloadToast">
						<i class="icon-download fs12"></i>
					</b-button>
				</template>

				<template v-if="item.status == 'Unprocessed'">
					<b-button variant="primary btn-xs fw700 w85" @click="bookedToast">
						Book
					</b-button>
				</template>
			</div>
		</li>
	</ul>

	<div class="pagination-bar">
		<div class="container-fluid">
			<div class="row">
				<div class="col-4">
					<div class="d-inline-flex wsnw align-items-center">
						<div class="d-block w50px">
							<multiselect
							class="pagination-items__pp"
							v-model="perPage"
							:searchable="false"
							:show-labels="false"
							:allow-empty="false"
							:options="pageOptions"
							@input="setPageNumber"
							></multiselect>
						</div>
						<span class="pl-8 fs12 lh24 text-gray-600 pagination-items"></span>
					</div>
				</div>
				<div class="col-8 text-right">
					<div class="d-inline-flex ml-auto wsnw align-items-center">
						<p class="fs12 lh24 text-gray-600 pr-14">
							Page: {{ currentPage }}
						</p>
						<b-button-group>
							<b-button @click="prevPage" :disabled="prevDisabled" variant="paginate left">
								<i class="icon-back fs12"></i>
							</b-button>

							<b-button @click="nextPage" :disabled="nextDisabled" variant="paginate right">
								<i class="icon-arrow fs12"></i>
							</b-button>
						</b-button-group>
					</div>
				</div>
			</div>
		</div>
	</div>

	<NdrFilterSidebar ref="filter" />
	<ResultNotFound v-if="items.length == 0 && !isBusy" />
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { createPopper } from "@popperjs/core";
import NdrFilterSidebar from "@/components/NdrFilterSidebar.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";

export default {
	name: "NdrSubmited",
	components: {
		Multiselect,
		DatePicker,
		NdrFilterSidebar,
		ResultNotFound,
	},
	data() {
		return {
			seller: this.$store.getters.user,
			pageOptions: this.$store.state.pageOptions,
			totalRows: 1,
			currentPage: parseInt(this.$route.query.page) || 1,
			perPage: parseInt(this.$route.query.offset) || this.$store.state.perPage,
			totalPages: 1,
			isBusy: true,
			prevDisabled: true,
			nextDisabled: false,
			startPage: 1,
			placement: "top",
			pagesArr: [],
			pagination: {},
			items: [],
			fields: [
			{ key: "awb", label: "AWB" },
			{ key: "orderId", label: "Order ID" },
			{ key: "carrier" },
			{ key: "carrierRemark", label: "Carrier Remark" },
			{ key: "customer" },
			{ key: "ndrDate", label: "NDR Date" },
			{ key: "ofdCount", label: "OFD Count" },
			{ key: "lastOFD", label: "Last OFD Date" },
			{ key: "actionBy", label: "Action Owner" },
			{ key: "action" },
			{ key: "sellerRemark", label: "Seller Remark" },
			{ key: "status", label: "Status" },
			],
			actionArrRettempt: ["reattempt","re-attempt"],
			actionSelected: 'Return',
			actionOptions: ["Re-Attempt", "Return"],
			selFilters: this.$store.getters.filters.ndr || {},
			filterResultsToggle: this.$store.getters.filters.ndr ? true : false,
			activeTab: parseInt(this.$route.query.tab) || 0,
			time3: null,
			downloading: false,
			uploading: false,
			file: new File([], '')
		};
	},
	created() {
		this.filterHeader();
		this.getItems();
	},
	methods: {
		async filterHeader() {
			if (this.seller.ndrEnabled) {
				let actionIndex = null;
				this.fields.map((element, index) => {
					if(element.key == "action") actionIndex = index;
				});
				if(actionIndex != null) this.fields.splice(actionIndex, 1);
				let remarkIndex = null;
				this.fields.map((element, index) => {
					if(element.key == "sellerRemark") remarkIndex = index;
				});
				if(remarkIndex != null) this.fields.splice(remarkIndex, 1);
			}
		},
		async getItems(params) {
			try {

				this.isBusy = true

				if(!params) {
					let params = {}
				}
				if(Object.keys(this.selFilters).length > 0) {
					params = {...{filtering: true}, ...this.selFilters}
				}

				const response  = await this.axios.get(`/ndr-new/0?page=${this.currentPage}&offset=${this.perPage}`, {params:params})
				this.items    = response.data.ndr
				this.isBusy   = false
				this.totalRows  = this.items.length;
				this.pagination = response.data.pagination

				this.setPagination()
				if(this.items.length < this.perPage) {
					this.nextDisabled = true
				}

				this.isBusy = false

			} catch (error) {
				this.isBusy     = false
				this.nextDisabled   = true
				this.popToast('failed', 'Failed!', error)
			}
		},
		setPagination() {
			this.nextDisabled = this.pagination.next ? false : true
			this.prevDisabled = this.pagination.prev ? false : true
			this.$router.push(`?tab=${this.activeTab}&page=${this.currentPage}&offset=${this.perPage}`).catch(()=>{})
		},
		setPageNumber() {
			this.currentPage = 1
			this.getItems()
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr   = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
			this.currentPage = 1;
			this.prevDisabled = true;
			if (this.totalPages == 1) {
				this.nextDisabled = true;
			} else if (this.totalPages > 1) {
				this.nextDisabled = false;
			}
		},
		updatePageNumber() {
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
		},
		rowClicked(item, index, event) {
			if(event.toElement && event.toElement.childElementCount == 0 && event.srcElement.className != 'multiselect__select'){
				let routeData = this.$router.resolve('/shipments/'+item.shipmentID);
				window.open(routeData.href, '_blank');
			}
		}, 
		nextPage() {
			this.currentPage++;
			this.getItems()
		},
		prevPage() {
			this.currentPage--
			this.getItems()
		},
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList, {
				placement: this.placement,
				modifiers: [
				{
					name: "offset",
					options: {
						offset: [0, -1],
					},
				},
				{
					name: "toggleClass",
					enabled: true,
					phase: "write",
					fn({ state }) {
						component.$el.classList.toggle(
							"drop-up",
							state.placement === "top"
							);
					},
				},
				],
			});
			return () => popper.destroy();
		},
		async downloadOrders() {
			try {
				this.downloading = true
				this.popToast("requested", "Download requested", "Your download should start now.");
				const res = await this.axios.get(`/ndr-new/0/export?page=${this.currentPage}&offset=${this.perPage}`)
				this.download(res.data.path, "NDR_"+ new Date().getTime()+".xlsx")
				this.filterResultsToggle = false;
			} catch(e) {
				console.log(e);
			}
			this.downloading = false
		},
		getFilters(value) {
			this.currentPage = 1
			this.filterResultsToggle = true;
			this.selFilters = this.$store.getters.filters.ndr
			this.getItems()
		},
		removeFilter(index) {
			const selFilters = {...this.selFilters}
			if(index == 'dateEnd' || index == 'dateStart') {
				delete selFilters.dateEnd
				delete selFilters.dateStart
				delete this.$refs.filter.selectedFilters.dateEnd
				delete this.$refs.filter.selectedFilters.dateStart
				delete this.$refs.filter.form.date
			}else{
				delete selFilters[index]
				delete this.$refs.filter.selectedFilters[index]
			}
			this.selFilters = selFilters
			this.$store.commit('setFilter', {'ndr': selFilters})
			delete this.$refs.filter.form[index]
			if(Object.keys(this.selFilters).length > 0) {
				this.getItems()
			}else{
				this.clearFilters()
			}
		},
		clearFilters() {
			this.filterResultsToggle = false;
			this.selFilters = {};
			this.$refs.filter.clearFilters()
			this.getItems({filtering: false})
		},
		popInput() {
			this.$refs.file.$el.click();
		},
		async uploadFile(file) {
			try {
				this.uploading = true
				let fd = new FormData()
				fd.append('file', file)
				const res = await this.axios.post('/ndr-new/bulk', fd)
				fd.delete('file')
				if(res.data.success == true) {
					this.popToast("booked", "Success!", res.data.message);
					this.getItems()
				} else{
					this.popToast("failed", "Upload Failed!", res.data.message);
				}
				if (res.data.path) {
					this.download(res.data.path, "NDR_Error"+ new Date().getTime()+".xlsx");
				}
			} catch(e) {
				console.log(e);
			}
			this.uploading = false
		},
		async submitForm() {
			let posts = {}
			let flag = false
			this.items.map(item => {
				if(item.selected) {
					flag = true
				}
				posts[item.id] = [item.selected, item.sellerRemark.remark]
			})
			if(flag) {
				const res = await this.axios.post('/ndr-new', posts);
				if(res.data.success == true) {
					this.popToast("booked", "Success!", res.data.message);
					this.getItems()
				}else{
					this.popToast("failed", "Failed!", res.data.message);
				}
			}else{
				this.popToast("failed", "Failed!", "Please choose any action then submit.");
			}
		}
	},
	computed: {
		windowWidth() {
			return this.$store.state.windowWidth;
		},
	},
	mounted() {
		this.totalRows = this.items.length;
		this.totalPages = Math.ceil(this.totalRows / this.perPage);
		for (var i = 1; i <= this.totalPages; i++) {
			this.pagesArr.push(i);
		}
		this.$root.$on("eventing", (data) => {
			console.log(data);
			this.editModal = !this.editModal;
		});
	},
	watch: {
		file: function(newValue) {
			if(newValue.size > 0) {
				this.uploadFile(newValue)
				this.file = new File([], '')
			}
		},
		currentPage: function() {
			this.prevDisabled = false;
			this.nextDisabled = false;
			if (this.currentPage == 1) {
				this.prevDisabled = true;
			}
			if (this.currentPage == this.totalPages) {
				this.nextDisabled = true;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
	.action-buttons {
		position: fixed;
		top: rem(94px);
		right: rem(36px);
	}
</style>