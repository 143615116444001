<template>
	<div>
		<div class="d-flex align-items-center justify-content-end action-buttons pt-70">
			<div class="hbreak d-none d-md-inline-flex"></div>
			<div class="divider mx-20 vam d-none d-md-flex"></div>
			<b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900">
				<span>
					Filters
				</span>
				<i class="icon-filter text-gray-600 pl-10"></i>
			</b-button>
		</div>

		<transition name="slide-right">
			<div class="select-bar d-flex ndr" v-if="filterResultsToggle">
				<div class="container-fluid d-flex align-items-center">
					<div class="d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0" >
						<div class="d-flex align-items-center">
							<span class="d-none d-md-flex mr-6 text-gray-600 wsnw" v-if="this.selFilters.length != '0'">
								Results for:
							</span>
							<ul class="d-none d-md-flex result-list">
								<li v-for="(selFilter, index) in selFilters" :key="index">
									<b-button class="mx-4 my-1" variant="tag" v-if="index!='filtering'">
										<span v-if="selFilter.hasOwnProperty('name')" class="wsnw">
											{{ selFilter.name }}
										</span>
										<span v-else class="wsnw text-truncate">
											{{ (selFilter.includes('\n') || selFilter.includes(',')) ? 'Multiple': selFilter }}
										</span>
										<i class="icon-close fs6" @click="removeFilter(index)"></i>
									</b-button>
								</li>
							</ul>

							<span class="d-flex d-md-none" v-if="this.selFilters.length != '0'">
								{{ this.selFilters.length }} Filters selected.
							</span>
							<span class="d-flex d-md-none" v-else>
								No filters selected.
							</span>
							<b-link @click="clearFilters()" class="ml-24 mr-12 vam">
								<span class="text-gray-900 wsnw">Clear filters</span>
								<i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
							</b-link>
						</div>
						<div class="d-flex align-items-center">
							<b-button v-if="items.length>0" variant="secondary" class="wsnw" :disabled="downloading" @click="downloadOrders">
								<i class="icon-download fs16"></i>
								<span class="d-none d-md-inline ml-0 ml-md-10">
									Download <b-spinner v-if="downloading" class="sm"></b-spinner>
								</span>
							</b-button>
							<b-button variant="secondary" class="text-gray-900 d-none d-md-flex ml-8" v-b-toggle.sidebarFilters>
								<span class="d-none d-md-flex">
									Filters
								</span>
								<i class="icon-filter text-gray-600 pl-0 pl-md-10"></i>
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</transition>

		<div v-if="this.windowWidth > 767">
			<b-table
			:items="items"
			:fields="computedFields"
			:per-page="perPage"
			:busy="isBusy"
			sticky-header
			no-border-collapse
			@row-clicked="rowClicked"
			class="ndr-table"
			>
			<template #table-busy>
				<div class="text-center text-danger my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>
			<template v-slot:cell(awb)="data">
				<b-link @click='popToast("booked", "Success", "Successfully copied to clipboard!")' v-clipboard:copy="data.item.awb" v-b-tooltip.hover.right class="cp underline-dashed text-decoration-none text-gray-900" title="Click to copy!">
					{{ data.item.awb }}
				</b-link>
			</template>
			<template v-slot:cell(orderId)="data">
				<span>
					{{ data.item.orderId }}
				</span>
			</template>
			<template v-slot:cell(carrierRemark)="data">
				<span class="d-block">
					{{ data.item.carrierRemark.remark }}
				</span>
			</template>

			<template v-slot:cell(lastOFD)="data">
				<span class="d-block">
					{{data.item.lastOFD}}
				</span>
			</template>

			<template v-slot:cell(customer)="data">
				<span class="d-block wsnw">
					{{ data.item.customer.name.substring(0, 30) }}
				</span>
				<span class="fs12 lh16 text-gray-600">
					{{ data.item.customer.id }}
				</span>
			</template>
			<template v-slot:cell(ndrDate)="data">
				<span class="d-block wsnw">
					{{ data.item.ndrDate.date }}
				</span>
				<span class="fs12 lh16 text-gray-600">
					{{ data.item.ndrDate.time }}
				</span>
			</template>
			<template v-slot:cell(sellerRemark)="data">
				<span class="d-block">
					{{ data.item.sellerRemark.remark || "" }}
				</span>
				<span class="fs12 lh16 text-gray-600 wsnw">
					{{ data.item.sellerRemark.remark ? data.item.sellerRemark.datetime : "" }} 
				</span>
			</template>
			<template v-slot:cell(action)="data">
				<span class="wsnw">{{ data.item.selected ? data.item.selected : '-' }}</span>
			</template>
			<template v-slot:cell(status)="data">
				<span class="wsnw tag" :class="data.item.status | lowercase">
					{{ data.item.status }}
				</span>
			</template>
		</b-table>
	</div>

	<ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
		<li v-for="item in items" :key="item.id">
			<div class="d-flex align-items-center justify-content-between w-100 mb-4">
				<span class="d-block fw500 pb-8 cp">
					Order ID: 
					{{ item.orderId }}
				</span>
			</div>

			<div class="info">
				<div class="d-flex w-100">
					<span class="carrier-name">
						{{ item.carrier }}
					</span>
				</div>
				<div class="d-flex align-items-center justify-content-between w-100">
					<span class="mb-4">
						{{ item.carrierRemark.remark }}
					</span>
				</div>

				<div class="d-flex align-items-center justify-content-between w-100 mt-8">
					<div class="d-flex flex-column">
						<span>
							{{ item.customer.name }}
						</span>
						<span class="fs13 text-gray-600">
							{{ item.customer.id }}
						</span>
					</div>
					<div class="d-flex flex-column text-right">
						<span>
							{{ item.sellerRemark.remark }}
						</span>
						<span class="fs12 text-gray-600">
							{{ item.sellerRemark.datetime }}
						</span>
					</div>
				</div>

				<span class="mt-8">
	                <strong>AWB: </strong> {{ item.awb }}
	            </span>

				<div class="d-flex align-items-center justify-content-between w-100 mt-8">
					<span class="text-gray-600">
						{{ item.ndrDate.date }} - {{ item.ndrDate.time }}
					</span>
					<div class="price ml-0">
						<span class="bg-box" :class="item.type">
							{{ item.type | uppercase }}
						</span>
						<template>
							<span class="wsnw tag" :class="item.status | lowercase">
								{{ item.status }}
							</span>
						</template>
					</div>
				</div>
			</div>
		</li>
	</ul>

<div class="pagination-bar">
	<div class="container-fluid">
		<div class="row">
			<div class="col-4">
				<div class="d-inline-flex wsnw align-items-center">
					<div class="d-block w50px">
						<multiselect
						class="pagination-items__pp"
						v-model="perPage"
						:searchable="false"
						:show-labels="false"
						:allow-empty="false"
						:options="pageOptions"
						@input="setPageNumber"
						></multiselect>
					</div>
					<span
					class="pl-8 fs12 lh24 text-gray-600 pagination-items"
					></span>
				</div>
			</div>
			<div class="col-8 text-right">
				<div class="d-inline-flex ml-auto wsnw align-items-center">
					<p class="fs12 lh24 text-gray-600 pr-14">
						Page: {{ currentPage }}
					</p>
					<b-button-group>
						<b-button @click="prevPage" :disabled="prevDisabled" variant="paginate left">
							<i class="icon-back fs12"></i>
						</b-button>

						<b-button @click="nextPage" :disabled="nextDisabled" variant="paginate right">
							<i class="icon-arrow fs12"></i>
						</b-button>
					</b-button-group>
				</div>
			</div>
		</div>
	</div>
</div>

<ResultNotFound v-if="items.length == 0 && !isBusy" />
<NdrFilterSidebar ref="filter" />
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { createPopper } from "@popperjs/core";
import NdrFilterSidebar from "@/components/NdrFilterSidebar.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";

export default {
	name: "NdrSubmited",
	components: {
		Multiselect,
		DatePicker,
		NdrFilterSidebar,
		ResultNotFound,
	},
	data() {
		return {
			pageOptions: this.$store.state.pageOptions,
			totalRows: 1,
			currentPage: parseInt(this.$route.query.page) || 1,
			perPage: parseInt(this.$route.query.offset) || this.$store.state.perPage,
			totalPages: 1,
			isBusy: true,
			prevDisabled: true,
			nextDisabled: false,
			startPage: 1,
			placement: "top",
			pagesArr: [],
			pagination: {},
			items: [],
			fields: [
			{ key: "awb", label: "AWB" },
			{ key: "orderId", label: "Order ID" },
			{ key: "carrier" },
			{ key: "carrierRemark", label: "Carrier Remark" },
			{ key: "customer" },
			{ key: "ndrDate", label: "NDR Date" },
			{ key: "ofdCount", label: "OFD Count" },
			{ key: "lastOFD", label: "Last OFD Date" },
			{ key: "actionBy"},
			{ key: "action" },
			{ key: "sellerRemark", label: "Seller Remark" },
			{ key: "shypliteRemark", label:"Shyplite Remark" },
			{ key: "status", label: "Status" },
			],
			actionArrRettempt: ["reattempt","re-attempt"],
			actionSelected: 'Return',
			actionOptions: ["Re-Attempt", "Return"],
			selFilters: this.$store.getters.filters.ndr || {},
			filterResultsToggle: this.$store.getters.filters.ndr ? true : false,
			activeTab: parseInt(this.$route.query.tab) || 0,
			time3: null,
			downloading: false,
		};
	},
	created() {
		this.getItems()
	},
	methods: {
		async getItems(params) {
			try {

				this.isBusy = true

				if(!params) {
					let params = {}
				}
				if(Object.keys(this.selFilters).length > 0) {
					params = {...{filtering: true}, ...this.selFilters}
				}

				const response 	= await this.axios.get(`/ndr-new/1?page=${this.currentPage}&offset=${this.perPage}`, {params:params})
				this.items 		= response.data.ndr
				this.isBusy 	= false
				this.totalRows 	= this.items.length;
				this.pagination = response.data.pagination

				this.setPagination()
				if(this.items.length < this.perPage) {
					this.nextDisabled = true
				}

				this.isBusy = false

			} catch (error) {
				this.isBusy 		= false
				this.nextDisabled 	= true
				this.popToast('failed', 'Failed!', error)
			}
		},
		setPagination() {
			this.nextDisabled = this.pagination.next ? false : true
			this.prevDisabled = this.pagination.prev ? false : true
			this.$router.push(`?tab=${this.activeTab}&page=${this.currentPage}&offset=${this.perPage}`).catch(()=>{})
		},
		setPageNumber() {
			this.currentPage = 1
			this.getItems()
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr 	= [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
			this.currentPage = 1;
			this.prevDisabled = true;
			if (this.totalPages == 1) {
				this.nextDisabled = true;
			} else if (this.totalPages > 1) {
				this.nextDisabled = false;
			}
		},
		updatePageNumber() {
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
		},
		rowClicked(item) {
			this.$router.push('/shipments/'+item.shipmentID)
		}, 
		nextPage() {
			this.currentPage++;
			this.getItems()
		},
		prevPage() {
			this.currentPage--
			this.getItems()
		},
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList, {
				placement: this.placement,
				modifiers: [
				{
					name: "offset",
					options: {
						offset: [0, -1],
					},
				},
				{
					name: "toggleClass",
					enabled: true,
					phase: "write",
					fn({ state }) {
						component.$el.classList.toggle(
							"drop-up",
							state.placement === "top"
							);
					},
				},
				],
			});
			return () => popper.destroy();
		},
		async downloadOrders() {
			try {
				this.downloading = true
				this.popToast("requested", "Download requested", "Your download should start now.");
				const res = await this.axios.get(`/ndr-new/1/export?page=${this.currentPage}&offset=${this.perPage}`)
				this.download(res.data.path, "NDR_"+ new Date().getTime()+".xlsx")
				this.filterResultsToggle = false;
			} catch(e) {
				console.log(e);
			}
			this.downloading = false
		},
		getFilters() {
			this.currentPage = 1
			this.filterResultsToggle = true;
			this.selFilters = this.$store.getters.filters.ndr
			this.getItems()
		},
		removeFilter(index) {
			const selFilters = {...this.selFilters}
			if(index == 'dateEnd' || index == 'dateStart') {
				delete selFilters.dateEnd
				delete selFilters.dateStart
				delete this.$refs.filter.selectedFilters.dateEnd
				delete this.$refs.filter.selectedFilters.dateStart
				delete this.$refs.filter.form.date
			}else{
				delete selFilters[index]
				delete this.$refs.filter.selectedFilters[index]
			}
			this.selFilters = selFilters
			this.$store.commit('setFilter', {'ndr': selFilters})
			delete this.$refs.filter.form[index]
			if(Object.keys(this.selFilters).length > 0) {
				this.getItems()
			}else{
				this.clearFilters()
			}
		},
		clearFilters() {
			this.filterResultsToggle = false;
			this.selFilters = {};
			this.$refs.filter.clearFilters()
			this.getItems({filtering: false})
		},
	},
	computed: {
		windowWidth() {
			return this.$store.state.windowWidth;
		},
		computedFields() {
			return this.fields;
		}
	},
	mounted() {
		this.totalRows = this.items.length;
		this.totalPages = Math.ceil(this.totalRows / this.perPage);
		for (var i = 1; i <= this.totalPages; i++) {
			this.pagesArr.push(i);
		}
		this.$root.$on("eventing", (data) => {
			this.editModal = !this.editModal;
		});
	},
	watch: {
		currentPage: function() {
			this.prevDisabled = false;
			this.nextDisabled = false;

			if (this.currentPage == 1) {
				this.prevDisabled = true;
			}

			if (this.currentPage == this.totalPages) {
				this.nextDisabled = true;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
	.action-buttons {
		position: fixed;
		top: rem(94px);
		right: rem(36px);
	}
</style>