<template>
	<div>
		<div class="d-flex align-items-center justify-content-end action-buttons recon_action-buttons pt-70">
			<div class="hbreak d-none d-md-inline-flex"></div>
			<b-form-file ref="file" v-model="file" accept=".xlsx, .xls" class="d-none" plain></b-form-file>

			<b-button v-if="errorButton && routeTab == 0" @click="downloadError" class="mr-15 mr-md-15 errorbtn">
				<i class="icon-download fs16"></i>
				<span class="d-none d-md-inline ml-0 ml-md-10">
					Error File
				</span>
			</b-button>

			<b-button v-if="routeTab == 0" @click="popInput" variant="secondary" class="mr-8 mr-md-0">
				<b-spinner v-if="uploading"></b-spinner>
				<i v-else class="icon-upload fs16"></i>
				<span class="d-none d-md-inline ml-0 ml-md-10">
					Bulk Upload
				</span>
			</b-button>

			<b-button variant="secondary" v-if="routeTab == 0 && items.length > 0" :disabled="downloading" class="mr-8 mr-md-0 ml-md-16" @click="downloadOrders">
				<b-spinner v-if="downloading"></b-spinner><i v-else class="icon-download fs16"></i>
				<span class="d-none d-md-inline ml-0 ml-md-10">
					Download
				</span>
			</b-button>

			<div v-if="routeTab == 0" class="hbreak d-none d-md-inline-flex"></div>
			<div v-if="routeTab == 0" class="divider mx-20 vam d-none d-md-flex"></div>
			<b-button v-b-toggle.sidebarFilters class="ndr-side-btn text-gray-900 ndr_filters_button reco_filter_btn">
				<span>
					Filters
				</span>
				<i class="icon-filter text-gray-600 pl-10"></i>
			</b-button>
		</div>

		<transition name="slide-right">
			<div class="select-bar d-flex ndr" v-if="filterResultsToggle">
				<div class="container-fluid d-flex align-items-center">
					<div class="d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0">
						<div class="d-flex align-items-center">
							<span class="d-none d-md-flex mr-6 text-gray-600 wsnw" v-if="this.visibleFilters.length != '0'">
								Results for:
							</span>


							<ul class="d-none d-md-flex result-list">
								<li v-for="(selFilter, index) in visibleFilters" :key="index">
									<b-button class="mx-4 my-1" variant="tag" v-if="index!='filtering'">
										<span v-if="selFilter.hasOwnProperty('name')" class="wsnw">
											{{ selFilter }}
										</span>
										<span v-else class="wsnw text-truncate">
											{{ (selFilter.includes('\n') || selFilter.includes(',')) ? 'Multiple': selFilter }}
										</span>
										<i class="icon-close fs6" @click="removeFilter(index)"></i>
									</b-button>
								</li>
							</ul>

							<span class="d-flex d-md-none" v-if="this.selFilters.length != '0'"> {{ this.selFilters.length }} Filters selected.
							</span>
							<span class="d-flex d-md-none" v-else>
								No filters selected.
							</span>
							<b-link @click="clearFilters()" class="ml-24 mr-12 vam">
								<span class="text-gray-900 wsnw">Clear filters</span>
								<i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
							</b-link>
						</div>
						<div class="d-flex align-items-center">
							<b-button variant="secondary" class="wsnw" v-if="(selFilters.dateEnd && daysFromDates()<=30) && items.length>0 " @click="reconExport" :disabled="exporting || !items.length">
								<b-spinner v-if="exporting"></b-spinner><i v-else class="icon-download fs16"></i>
								<span class="d-none d-md-inline ml-0 ml-md-10">
									Export
								</span>
							</b-button>

							<div v-if="routeTab == 0" class="hbreak d-none d-md-inline-flex"></div>
							<div v-if="routeTab == 0" class="divider mx-20 vam d-none d-md-flex"></div>

							<b-button variant="secondary" class="text-gray-900 d-none d-md-flex ml-8" v-b-toggle.sidebarFilters>
								<span class="d-none d-md-flex">
									Filters
								</span>
								<i class="icon-filter text-gray-600 pl-0 pl-md-10"></i>
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</transition>


		<div v-if="this.windowWidth > 767">
			<b-table
			:items="items"
			:fields="fields"
			:per-page="perPage"
			:busy="isBusy"
			sticky-header
			no-border-collapse
			class="ndr-table"
			>
			<template #table-busy>
				<div class="text-center text-danger my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>
			<template v-slot:cell(awb)="data">
				<b-link @click='popToast("booked", "Success", "Successfully copied to clipboard!")' v-clipboard:copy="data.item.awb" v-b-tooltip.hover.right class="cp underline-dashed text-decoration-none text-gray-900" title="Click to copy!">
					{{ data.item.awb }}
				</b-link>
			</template>
			<template v-slot:cell(orderId)="data">
					{{ data.item.orderId }}
			</template>
			<template v-slot:cell(carrierID)="data">
					<span class="carrier font-weight-bold" :data-carrier="data.item.carrierID">{{ data.item.carrier }} </span>
			</template>
			<template v-slot:cell(customer)="data">
				<span class="wsnw d-block">
					{{ data.item.customer.name.substring(0, 35) }}
				</span>
				<span class="fs12 lh16 text-gray-600">
					{{ data.item.customer.id }}
				</span>
			</template>
			<template v-slot:cell(ndrDate)="data">
				<span class="d-block wsnw">
					{{ data.item.ndrDate.date }}
				</span>
				<span class="fs12 lh16 text-gray-600">
					{{ data.item.ndrDate.time }}
				</span>
			</template>
			<template v-slot:cell(sellerRemark)="data">
				<b-form-input type="text" placeholder="Seller remark" v-model="data.item.sellerRemark.remark" class="small w120px"></b-form-input>
			</template>
			<template v-slot:cell(action)="data">
				<multiselect
				id="action"
				v-model="data.item.selected"
				:options="actionOptions"
				:searchable="false"
				:show-labels="false"
				placeholder="- Select -"
				class="h36-select w120px"
				></multiselect>
			</template>
			<template v-slot:cell(status)="data">
				<template>
					<span class="wsnw tag" :class="data.item.status | lowercase">
						{{ data.item.status }}
					</span>
				</template>
			</template>
		</b-table>
	</div>

	<ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
		<li v-for="item in items" :key="item.id">
			<div class="d-flex align-items-center justify-content-between w-100 mb-4">
				<span class="d-block fw500 pb-8 cp">
					Order
					{{ item.orderId }}
				</span>
				<multiselect
				id="action"
				:value="item.selected"
				:options="actionOptions"
				:searchable="false"
				:show-labels="false"
				placeholder="- Select -"
				class="h36-select"
				></multiselect>
			</div>

			<div class="info">
				<div class="d-flex w-100">
					<span class="carrier-name">
						{{ item.carrier }}
					</span>
				</div>
				<div class="d-flex align-items-center justify-content-between w-100">
					<span class="mb-4">
						{{ item.carrierRemark.remark }}
					</span>
				</div>
				<!-- <div class="d-flex align-items-center justify-content-between w-100">
					<span class="fs13 text-gray-600">
						{{ item.carrierRemark.status }}
					</span>
				</div> -->
				<div class="d-flex align-items-center justify-content-between w-100 mt-8">
					<div class="d-flex flex-column">
						<span>
							{{ item.customer.name }}
						</span>
						<span class="fs13 text-gray-600">
							{{ item.customer.id }}
						</span>
					</div>
					<div class="d-flex flex-column text-right">
						<span>
							{{ item.sellerRemark.remark }}
						</span>
						<span class="fs12 text-gray-600">
							{{ item.sellerRemark.datetime }}
						</span>
					</div>
				</div>
				<div class="d-flex align-items-center justify-content-between w-100 mt-8">
					<span class="mt-8 text-gray-600">
						{{ item.ndrDate.date }} - {{ item.ndrDate.time }}
					</span>
					<div class="price ml-0">
						<span class="bg-box" :class="item.type">
							{{ item.type | uppercase }}
						</span>
						<template>
							<span class="wsnw tag" :class="item.status | lowercase">
								{{ item.status }}
							</span>
						</template>
					</div>
				</div>
			</div>
			<div class="d-flex w-100 align-items-center  justify-content-between">
				<span class="fs12 lh16 text-gray-600">
					{{ item.date }}
				</span>

				<template v-if="item.status == 'Processed' || item.status == 'Error'">
					<span class="tag" :class="item.status | lowercase">
						{{ item.status }}
					</span>
				</template>

				<template v-if="item.status == 'Booked'">
					<b-button variant="primary btn-xs" @click="downloadToast">
						<i class="icon-download fs12"></i>
					</b-button>
				</template>

				<template v-if="item.status == 'Unprocessed'">
					<b-button variant="primary btn-xs fw700 w85" @click="bookedToast">
						Book
					</b-button>
				</template>
			</div>
		</li>
	</ul>

	<div class="pagination-bar">
		<div class="container-fluid">
			<div class="row">
				<div class="col-4">
					<div class="d-inline-flex wsnw align-items-center">
						<div class="d-block w50px">
							<multiselect
							class="pagination-items__pp"
							v-model="perPage"
							:searchable="false"
							:show-labels="false"
							:allow-empty="false"
							:options="pageOptions"
							@input="setPageNumber"
							></multiselect>
						</div>
						<span class="pl-8 fs12 lh24 text-gray-600 pagination-items"></span>
					</div>
				</div>
				<div class="col-8 text-right">
					<div class="d-inline-flex ml-auto wsnw align-items-center">
						<p class="fs12 lh24 text-gray-600 pr-14">
							Page: {{ currentPage }}
						</p>
						<b-button-group>
							<b-button @click="prevPage" :disabled="prevDisabled" variant="paginate left">
								<i class="icon-back fs12"></i>
							</b-button>

							<b-button @click="nextPage" :disabled="nextDisabled || noDataonNext" variant="paginate right">
								<i class="icon-arrow fs12"></i>
							</b-button>
						</b-button-group>
					</div>
				</div>
			</div>
		</div>
	</div>

	<ReconFilterSidebar ref="filter" />
	<ResultNotFound v-if="items.length == 0 && !isBusy" />
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { createPopper } from "@popperjs/core";
import ReconFilterSidebar from "@/views/dispute/ReconFilterSidebar.vue";
import ResultNotFound from "@/components/ResultNotFound.vue";

export default {
	name: "ReconciliationRequired",
	components: {
		Multiselect,
		DatePicker,
		ReconFilterSidebar,
		ResultNotFound,
	},
	data() {
		return {
			pageOptions: this.$store.state.pageOptions,
			totalRows: 1,
			currentPage: parseInt(this.$route.query.page) || 1,
			perPage: parseInt(this.$route.query.offset) || this.$store.state.perPage,
			totalPages: 1,
			isBusy: true,
			prevDisabled: true,
			nextDisabled: false,
			exporting :false,
			startPage: 1,
			placement: "top",
			pagesArr: [],
			pagination: {},
			items: [],
			uploading:false,
			exportData:null,
			fields: [
			{ key: "awb", label: "AWB"},
			{ key: "carrierID", label: "Carrier"},
			{ key: "mode"},
			{ key: "quantity"},
			{ key: "volumetricWeight", label: "Volumetric Weight", tdClass: "text-center", thClass: "text-center" },
			{ key: "declearWeight", label: "Declared Weight", tdClass: "text-center", thClass: "text-center" },
			{ key: "carrierWeight", label: "Carrier Weight", tdClass: "text-center", thClass: "text-center" },
			{ key: "claimedWeight", label: "Claimed Weight", tdClass: "text-center", thClass: "text-center" },
			{ key: "acceptedWeight", label: "Accepted Weight", tdClass: "text-center", thClass: "text-center" },
			],
			actionArrRettempt: ["reattempt","re-attempt"],
			actionSelected: 'Return',
			actionOptions: ["Re-Attempt", "Return"],
			selFilters: this.$store.getters.filters.recon || {},
			filterResultsToggle: this.$store.getters.filters.recon ? true : false,
			time3: null,
			downloading: false,
			file: new File([], ''),
			routeTab:parseInt(this.$route.query.tab) || 0,
			errorButton:this.$store.state.errorButton || false,
			errorFile:null,
			visibleFilters:this.$store.getters.filters.recon || {},
			noDataonNext:false,
			showExport:false
		};
	},
	created() {
		this.getItems()
		this.getVisibleFilters(this.visibleFilters)
	},
	methods: {
		getVisibleFilters(obj) {
			const visible = Object.assign({}, obj)
			for (const propName in visible) {
			    if (visible[propName] === null || visible[propName] === "") {
			      delete visible[propName];
			    }
			  }
			  this.visibleFilters = visible
		},
		async getItems(params) {
			this.noDataonNext = false
			try {

				this.isBusy = true

				if(!params) {
					let params = {}
				}
				if(Object.keys(this.selFilters).length > 0) {
					params = {...{filtering: true}, ...this.selFilters}
				}

				this.exportData = params

				const response  = await this.axios.get(`/dispute/reconciliation/${this.routeTab}?page=${this.currentPage}&offset=${this.perPage}`, {params:params})
				this.items = response.data.data.result
				let quant = JSON.parse(response.data.data.result[0].items)
				let quantity = 0;
				this.items.map(i => {
					let items = JSON.parse(i.items)
					let quantity = 0
					items.forEach(i => quantity += parseInt(i.quantity))
					i.quantity = quantity

					let carriers = this.$store.state.carriers
					carriers.forEach(j => {
						if (j.value == i.carrierID) {
							i['carrier'] = j.name
						}
					})

					if(i.claimedWeight == 0) {
						i['claimedWeight'] = "-"
					}  

					if (i.acceptedWeight == 0) {
						i['acceptedWeight'] = "-"
					}

					if (i.volumetricWeight == 0) {
						i['volumetricWeight'] = "N/A"
					}

					let modes = this.$store.state.modes
					modes.forEach(j => {
						if (j.value == i.mode) {
							i['mode'] = j.name
						} 
					})
				})

				this.isBusy   = false
				this.totalRows  = this.items.length;
				this.pagination = response.data.data.pagination


				this.setPagination()
				if(this.items.length < this.perPage) {
					this.nextDisabled = true
				}



				this.isBusy = false

			} catch (error) {
				this.isBusy     = false
				this.nextDisabled   = true
				// this.popToast('failed', 'Failed!', error)
			}
			this.$router.push(`?tab=${this.routeTab}&page=${this.currentPage}&offset=${this.perPage}`).catch(()=>{})

		},
		setPagination() {
			this.nextDisabled = this.pagination.next ? false : true
			this.prevDisabled = this.pagination.prev ? false : true

		},
		setPageNumber() {
			this.currentPage = 1
			this.getItems()
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr   = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
			this.currentPage = 1;
			this.prevDisabled = true;
			if (this.totalPages == 1) {
				this.nextDisabled = true;
			} else if (this.totalPages > 1) {
				this.nextDisabled = false;
			}
		},
		updatePageNumber() {
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
		},
		nextPage() {
			this.currentPage++;
			this.getItems()
		},
		prevPage() {
			this.currentPage--
			this.getItems()
		},
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList, {
				placement: this.placement,
				modifiers: [
				{
					name: "offset",
					options: {
						offset: [0, -1],
					},
				},
				{
					name: "toggleClass",
					enabled: true,
					phase: "write",
					fn({ state }) {
						component.$el.classList.toggle(
							"drop-up",
							state.placement === "top"
							);
					},
				},
				],
			});
			return () => popper.destroy();
		},
		async downloadOrders() {
			try {
				this.downloading = true
				this.popToast("requested", "Download requested", "Your download should start now.");
				const res = await this.axios.get('/dispute/export/reconciliation')
				window.open(res.data.path)
			} catch(e) {
				this.popToast("failed", "Failed", "Export generation failed. Please try again");
				console.log(e);
			}
			this.downloading = false
		},
		getFilters(value) {
			this.currentPage = 1
			this.filterResultsToggle = true;
			this.selFilters = this.$store.getters.filters.recon
			this.visibleFilters = Object.fromEntries(
    			Object.entries(this.selFilters).filter(([key, value]) => value != "") )
			this.getItems()
		},
		daysFromDates() {
          const date1 = new Date(this.selFilters.dateStart);
          const date2 = new Date(this.selFilters.dateEnd);
          const diffTime = Math.abs(date2 - date1);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          return diffDays
        },
        async reconExport() {
        	this.exporting = true
        	try {
        		const res = await this.axios.get(`/dispute/export/${this.routeTab}/reconciliation`,{ params: this.exportData })
        		if (res.data.path) {
        			window.open(res.data.path)
        			this.popToast("requested", "Export generated", "Your Reconciliation Export is getting downloaded");
        		} else {
        			this.popToast("failed", "Failed", "Data Fetching Failed!!! Please try again");
        		}
        	} catch(e) {
        		// statements
        		this.popToast("failed", "Failed", "Data Fetching Failed!!! Please try again");
        		console.error(e)
        	}
        	this.exporting = false
        },
		removeFilter(index) {
			const selFilters = {...this.selFilters}

			if(index == 'dateEnd' || index == 'dateStart') {
        		selFilters.dateEnd = ""
        		selFilters.dateStart = ""
        		delete this.visibleFilters.dateEnd
        		delete this.visibleFilters.dateStart
        		delete this.$refs.filter.selectedFilters.dateEnd
        		delete this.$refs.filter.selectedFilters.dateStart
        		delete this.$refs.filter.form.date
        	} else {
        		selFilters[index] = ""
				delete this.visibleFilters[index]
				delete this.$refs.filter.selectedFilters[index]
        	}

			this.selFilters = selFilters
			this.$store.commit('setFilter', {'recon': selFilters})
			delete this.$refs.filter.form[index]
			if(Object.keys(this.visibleFilters).length > 0) {
				this.getItems()
			}else{
				this.clearFilters()
			}
		},
		clearFilters() {
			this.filterResultsToggle = false;
			this.selFilters = {};
			this.$refs.filter.clearFilters()
			this.getItems({filtering: false})
		},
		popInput() {
			this.$refs.file.$el.click();
		},
		async uploadFile(file) {
			this.uploading = true
			if (!(file.name.split('.').pop().includes('xls') || file.name.split('.').pop().includes('xlsx'))) {
				this.popToast("failed", "File Error!", "File format not supported")
				this.file = null
				this.uploading = false
			} else {
				try {
					let fd = new FormData()
					fd.append('file', file)
					const res = await this.axios.post('/dispute/reconciliation', fd)
					fd.delete('file')
					if (res.data.path) {
						this.errorButton = true
						this.errorFile = res.data.path
						this.popToast("failed", "Failed!", "Please Download the Error File");
					} else {
						this.errorButton = false
						this.errorFile = null
						this.popToast("booked", "Success!", "File Uploaded Successfully");
						this.getItems();
					}
					this.uploading = false
					// if(res.data.success == true) {
					// 	this.popToast("booked", "Success!", res.data.message);
					// 	this.getItems()
					// }else{
					// 	this.popToast("failed", "Upload Failed!", res.data.message);
					// }
				} catch(e) {
					this.file = null
					this.errorButton = false
					this.errorFile = null
					this.uploading = false
					this.popToast("failed", "File Error!", "The specified key does not exist.")
				}
			}
			this.$store.commit('seterrorbutton', this.errorButton)
		},
		downloadError() {
			this.popToast("requested", "Download requested", "Your download should start now.");
			this.download(this.errorFile , "Reconciliation Error.xlsx")
			this.errorButton = false
			this.$store.commit('seterrorbutton', this.errorButton)
		}
	},
	computed: {
		windowWidth() {
			return this.$store.state.windowWidth;
		},
	},
	mounted() {
		this.totalRows = this.items.length;
		this.totalPages = Math.ceil(this.totalRows / this.perPage);
		for (var i = 1; i <= this.totalPages; i++) {
			this.pagesArr.push(i);
		}
		this.$root.$on("eventing", (data) => {
			this.editModal = !this.editModal;
		});
	},
	watch: {
		items:function(newValue) {
			if (!newValue.length && this.currentPage > 1) {
				this.currentPage--;
				this.getItems();
				this.noDataonNext = true;
			} else {
				// statement
			}
		},
		file: function(newValue) {
			if(newValue && newValue.size > 0) {
				this.uploadFile(newValue)
				this.file = new File([], '')
			}
		},
		currentPage: function() {
			this.prevDisabled = false;
			this.nextDisabled = false;

			if (this.currentPage == 1) {
				this.prevDisabled = true;
			}

			if (this.currentPage == this.totalPages) {
				this.nextDisabled = true;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.ndr-table{height: 1000px}
.errorbtn{color: #fff !important;background: #ef5a5a !important;}
.carrier[data-carrier="2"] {
  color: #32A8CB;
}
.carrier[data-carrier="3"] {
  color: #BC0014;
}
.carrier[data-carrier="4"] {
  color: #a064e6;
}
.carrier[data-carrier="5"] {
  color: #EE932C;
}
.carrier[data-carrier="6"] {
  color: #F48D1A;
}
.carrier[data-carrier="7"] {
  color: #0B3082;
}
.carrier[data-carrier="8"] {
  color: #E9201F;
}
.carrier[data-carrier="9"] {
  color: #230965;
}
.carrier[data-carrier="10"] {
  color: #33BB2C;
}
.carrier[data-carrier="11"] {
  color: #15A550;
}
.carrier[data-carrier="12"] {
  color: #127998;
}
.carrier[data-carrier="13"] {
  color: #134A1F;
}
.carrier[data-carrier="14"] {
  color: #36A94F;
}
.carrier[data-carrier="15"] {
  color: #36A94F;
}
.carrier[data-carrier="16"] {
  color: #da433e;
}
.carrier[data-carrier="17"] {
  color: #ec9605;
}
.carrier[data-carrier="18"] {
  color: #adce49;
}
.carrier[data-carrier="19"] {
  color: #2a75c9;
}
.carrier[data-carrier="20"] {
  color: #8e2927;
}
.carrier[data-carrier="21"] {
  color: #36A94F;
}
.carrier[data-carrier="22"] {
  color: #ee3526;
}
.carrier[data-carrier="23"] {
  color: #353896;
}
.carrier[data-carrier="24"] {
  color: #0d4689;
}
.carrier[data-carrier="25"] {
  color: #f16925;
}
.carrier[data-carrier="26"] {
  color: #0ef0b5;
}
.carrier[data-carrier="27"] {
  color: #138707;
}
.action-buttons {
	position: fixed;
	top: rem(94px);
	right: rem(36px);
	@include media-breakpoint-only(md) {
		top: rem(90px);
		right: rem(20px);
	}
	@include media-breakpoint-down(sm) {
		z-index: 10;
		top: rem(86px);
		right: rem(20px);
	}
}
</style>
