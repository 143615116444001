var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-end action-buttons ndr_action_buttons"
        },
        [
          _c("div", { staticClass: "hbreak d-none d-md-inline-flex" }),
          _c("b-form-file", {
            ref: "file",
            staticClass: "d-none",
            attrs: { accept: ".xlsx, .xls", plain: "" },
            model: {
              value: _vm.file,
              callback: function($$v) {
                _vm.file = $$v
              },
              expression: "file"
            }
          }),
          _vm.items.length > 0 && _vm.seller.ndrEnabled == 0
            ? _c(
                "b-button",
                {
                  staticClass: "mr-8 mr-md-16",
                  attrs: { variant: "primary", disabled: _vm.downloading },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Submit ")]
              )
            : _vm._e(),
          _vm.items.length > 0 && _vm.seller.ndrEnabled == 0
            ? _c(
                "b-button",
                {
                  staticClass: "mr-8 mr-md-0",
                  attrs: { disabled: _vm.uploading, variant: "secondary" },
                  on: { click: _vm.popInput }
                },
                [
                  _c("i", { staticClass: "icon-upload fs16" }),
                  _c(
                    "span",
                    { staticClass: "d-none d-md-inline ml-0 ml-md-10" },
                    [
                      _vm._v(" Bulk Upload "),
                      _vm.uploading
                        ? _c("b-spinner", { staticClass: "sm" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm.items.length > 0 && _vm.seller.ndrEnabled == 0
            ? _c(
                "b-button",
                {
                  staticClass: "mr-8 mr-md-0 ml-md-16",
                  attrs: { variant: "secondary", disabled: _vm.downloading },
                  on: { click: _vm.downloadOrders }
                },
                [
                  _c("i", { staticClass: "icon-download fs16" }),
                  _c(
                    "span",
                    { staticClass: "d-none d-md-inline ml-0 ml-md-10" },
                    [
                      _vm._v(" Download "),
                      _vm.downloading
                        ? _c("b-spinner", { staticClass: "sm" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "hbreak d-none d-md-inline-flex" }),
          _c("div", { staticClass: "divider mx-20 vam d-none d-md-flex" }),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.sidebarFilters",
                  modifiers: { sidebarFilters: true }
                }
              ],
              staticClass: "ndr-side-btn text-gray-900"
            },
            [
              _c("span", [_vm._v(" Filters ")]),
              _c("i", { staticClass: "icon-filter text-gray-600 pl-10" })
            ]
          )
        ],
        1
      ),
      _c("transition", { attrs: { name: "slide-right" } }, [
        _vm.filterResultsToggle
          ? _c("div", { staticClass: "select-bar d-flex ndr" }, [
              _c(
                "div",
                { staticClass: "container-fluid d-flex align-items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          this.selFilters.length != "0"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "d-none d-md-flex mr-6 text-gray-600 wsnw"
                                },
                                [_vm._v(" Results for: ")]
                              )
                            : _vm._e(),
                          _c(
                            "ul",
                            { staticClass: "d-none d-md-flex result-list" },
                            _vm._l(_vm.selFilters, function(selFilter, index) {
                              return _c(
                                "li",
                                { key: index },
                                [
                                  index != "filtering"
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "mx-4 my-1",
                                          attrs: { variant: "tag" }
                                        },
                                        [
                                          selFilter.hasOwnProperty("name")
                                            ? _c(
                                                "span",
                                                { staticClass: "wsnw" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(selFilter.name) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "wsnw text-truncate"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        selFilter.includes(
                                                          "\n"
                                                        ) ||
                                                          selFilter.includes(
                                                            ","
                                                          )
                                                          ? "Multiple"
                                                          : selFilter
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                          _c("i", {
                                            staticClass: "icon-close fs6",
                                            on: {
                                              click: function($event) {
                                                return _vm.removeFilter(index)
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          this.selFilters.length != "0"
                            ? _c("span", { staticClass: "d-flex d-md-none" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(this.selFilters.length) +
                                    " Filters selected. "
                                )
                              ])
                            : _c("span", { staticClass: "d-flex d-md-none" }, [
                                _vm._v(" No filters selected. ")
                              ]),
                          _c(
                            "b-link",
                            {
                              staticClass: "ml-24 mr-12 vam",
                              on: {
                                click: function($event) {
                                  return _vm.clearFilters()
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-gray-900 wsnw" },
                                [_vm._v("Clear filters")]
                              ),
                              _c("i", {
                                staticClass:
                                  "icon-close-variant text-gray-900 fw600 ml-8 fs8"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                variant: "secondary",
                                disabled: _vm.downloading
                              },
                              on: { click: _vm.downloadOrders }
                            },
                            [
                              _c("i", { staticClass: "icon-download fs16" }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-none d-md-inline ml-0 ml-md-10"
                                },
                                [_vm._v(" Download ")]
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.sidebarFilters",
                                  modifiers: { sidebarFilters: true }
                                }
                              ],
                              staticClass:
                                "text-gray-900 d-none d-md-flex ml-8",
                              attrs: { variant: "secondary" }
                            },
                            [
                              _c("span", { staticClass: "d-none d-md-flex" }, [
                                _vm._v(" Filters ")
                              ]),
                              _c("i", {
                                staticClass:
                                  "icon-filter text-gray-600 pl-0 pl-md-10"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ]),
      this.windowWidth > 767
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "ndr-table",
                attrs: {
                  items: _vm.items,
                  fields: _vm.fields,
                  "per-page": _vm.perPage,
                  busy: _vm.isBusy,
                  "sticky-header": "",
                  "no-border-collapse": ""
                },
                on: { "row-clicked": _vm.rowClicked },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head(actionBy)",
                      fn: function(data) {
                        return [
                          _c(
                            "span",
                            [
                              _vm._v(" " + _vm._s(data.field.label) + " "),
                              _c("i", {
                                staticClass: "icon-disclaimer fs12 vam",
                                attrs: { id: data.field.label }
                              }),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: data.field.label,
                                    placement: "auto",
                                    title: "Popover!",
                                    triggers: "hover focus"
                                  }
                                },
                                [
                                  _c("span", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "min-width": "200px",
                                          color: "#828b9d",
                                          "font-weight": "400"
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _c("b", [_vm._v("Shyplite:")]),
                                          _vm._v(
                                            " Action Performed by Shyplite"
                                          )
                                        ]),
                                        _c("br"),
                                        _c("div", [
                                          _c("b", [_vm._v("Seller:")]),
                                          _vm._v(" Action Required by Seller")
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-danger my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(awb)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-link",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: data.item.awb,
                                  expression: "data.item.awb",
                                  arg: "copy"
                                },
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.right",
                                  modifiers: { hover: true, right: true }
                                }
                              ],
                              staticClass:
                                "cp underline-dashed text-decoration-none text-gray-900",
                              attrs: { title: "Click to copy!" },
                              on: {
                                click: function($event) {
                                  return _vm.popToast(
                                    "booked",
                                    "Success",
                                    "Successfully copied to clipboard!"
                                  )
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(data.item.awb) + " ")]
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(orderId)",
                      fn: function(data) {
                        return [_vm._v(" " + _vm._s(data.item.orderId) + " ")]
                      }
                    },
                    {
                      key: "cell(carrierRemark)",
                      fn: function(data) {
                        return [
                          _vm._v(
                            " " + _vm._s(data.item.carrierRemark.remark) + " "
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(lastOFD)",
                      fn: function(data) {
                        return [_vm._v(" " + _vm._s(data.item.lastOFD) + " ")]
                      }
                    },
                    {
                      key: "cell(customer)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "wsnw d-block" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.customer.name.substring(0, 35)
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "span",
                            { staticClass: "fs12 lh16 text-gray-600" },
                            [_vm._v(" " + _vm._s(data.item.customer.id) + " ")]
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(ndrDate)",
                      fn: function(data) {
                        return [
                          _c("span", { staticClass: "d-block wsnw" }, [
                            _vm._v(" " + _vm._s(data.item.ndrDate.date) + " ")
                          ]),
                          _c(
                            "span",
                            { staticClass: "fs12 lh16 text-gray-600" },
                            [_vm._v(" " + _vm._s(data.item.ndrDate.time) + " ")]
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(sellerRemark)",
                      fn: function(data) {
                        return [
                          _c("b-form-input", {
                            staticClass: "small w120px",
                            attrs: {
                              type: "text",
                              placeholder: "Seller remark"
                            },
                            model: {
                              value: data.item.sellerRemark.remark,
                              callback: function($$v) {
                                _vm.$set(data.item.sellerRemark, "remark", $$v)
                              },
                              expression: "data.item.sellerRemark.remark"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "cell(action)",
                      fn: function(data) {
                        return [
                          _c("multiselect", {
                            staticClass: "h36-select w120px",
                            attrs: {
                              id: "action",
                              options: _vm.actionOptions,
                              searchable: false,
                              "show-labels": false,
                              placeholder: "- Select -"
                            },
                            model: {
                              value: data.item.selected,
                              callback: function($$v) {
                                _vm.$set(data.item, "selected", $$v)
                              },
                              expression: "data.item.selected"
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          [
                            _c(
                              "span",
                              {
                                staticClass: "wsnw tag",
                                class: _vm._f("lowercase")(data.item.status)
                              },
                              [_vm._v(" " + _vm._s(data.item.status) + " ")]
                            )
                          ]
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  182717086
                )
              })
            ],
            1
          )
        : _c(
            "ul",
            {
              staticClass: "table-cards table-cards--shipments d-flex d-md-none"
            },
            _vm._l(_vm.items, function(item) {
              return _c("li", { key: item.id }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between w-100 mb-4"
                  },
                  [
                    _c("span", { staticClass: "d-block fw500 pb-8 cp" }, [
                      _vm._v(" Order ID: " + _vm._s(item.orderId) + " ")
                    ]),
                    _c("multiselect", {
                      staticClass: "h36-select",
                      attrs: {
                        id: "action",
                        value: item.selected,
                        options: _vm.actionOptions,
                        searchable: false,
                        "show-labels": false,
                        placeholder: "- Select -"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "d-flex w-100" }, [
                    _c("span", { staticClass: "carrier-name" }, [
                      _vm._v(" " + _vm._s(item.carrier) + " ")
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between w-100"
                    },
                    [
                      _c("span", { staticClass: "mb-4" }, [
                        _vm._v(" " + _vm._s(item.carrierRemark.remark) + " ")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between w-100 mt-8"
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("span", [
                          _vm._v(" " + _vm._s(item.customer.name) + " ")
                        ]),
                        _c("span", { staticClass: "fs13 text-gray-600" }, [
                          _vm._v(" " + _vm._s(item.customer.id) + " ")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column text-right" },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(item.sellerRemark.remark) + " ")
                          ]),
                          _c("span", { staticClass: "fs12 text-gray-600" }, [
                            _vm._v(
                              " " + _vm._s(item.sellerRemark.datetime) + " "
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _c("span", { staticClass: "mt-8" }, [
                    _c("strong", [_vm._v("AWB: ")]),
                    _vm._v(" " + _vm._s(item.awb) + " ")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between w-100 mt-8"
                    },
                    [
                      _c("span", { staticClass: "text-gray-600" }, [
                        _vm._v(
                          " " +
                            _vm._s(item.ndrDate.date) +
                            " - " +
                            _vm._s(item.ndrDate.time) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "price ml-0" },
                        [
                          _c(
                            "span",
                            { staticClass: "bg-box", class: item.type },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("uppercase")(item.type)) +
                                  " "
                              )
                            ]
                          ),
                          [
                            _c(
                              "span",
                              {
                                staticClass: "wsnw tag",
                                class: _vm._f("lowercase")(item.status)
                              },
                              [_vm._v(" " + _vm._s(item.status) + " ")]
                            )
                          ]
                        ],
                        2
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex w-100 align-items-center  justify-content-between"
                  },
                  [
                    _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                      _vm._v(" " + _vm._s(item.date) + " ")
                    ]),
                    item.status == "Processed" || item.status == "Error"
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "tag",
                              class: _vm._f("lowercase")(item.status)
                            },
                            [_vm._v(" " + _vm._s(item.status) + " ")]
                          )
                        ]
                      : _vm._e(),
                    item.status == "Booked"
                      ? [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary btn-xs" },
                              on: { click: _vm.downloadToast }
                            },
                            [_c("i", { staticClass: "icon-download fs12" })]
                          )
                        ]
                      : _vm._e(),
                    item.status == "Unprocessed"
                      ? [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary btn-xs fw700 w85" },
                              on: { click: _vm.bookedToast }
                            },
                            [_vm._v(" Book ")]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            }),
            0
          ),
      _c("div", { staticClass: "pagination-bar" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [
              _c(
                "div",
                { staticClass: "d-inline-flex wsnw align-items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "d-block w50px" },
                    [
                      _c("multiselect", {
                        staticClass: "pagination-items__pp",
                        attrs: {
                          searchable: false,
                          "show-labels": false,
                          "allow-empty": false,
                          options: _vm.pageOptions
                        },
                        on: { input: _vm.setPageNumber },
                        model: {
                          value: _vm.perPage,
                          callback: function($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", {
                    staticClass: "pl-8 fs12 lh24 text-gray-600 pagination-items"
                  })
                ]
              )
            ]),
            _c("div", { staticClass: "col-8 text-right" }, [
              _c(
                "div",
                {
                  staticClass: "d-inline-flex ml-auto wsnw align-items-center"
                },
                [
                  _c("p", { staticClass: "fs12 lh24 text-gray-600 pr-14" }, [
                    _vm._v(" Page: " + _vm._s(_vm.currentPage) + " ")
                  ]),
                  _c(
                    "b-button-group",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.prevDisabled,
                            variant: "paginate left"
                          },
                          on: { click: _vm.prevPage }
                        },
                        [_c("i", { staticClass: "icon-back fs12" })]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.nextDisabled,
                            variant: "paginate right"
                          },
                          on: { click: _vm.nextPage }
                        },
                        [_c("i", { staticClass: "icon-arrow fs12" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c("NdrFilterSidebar", { ref: "filter" }),
      _vm.items.length == 0 && !_vm.isBusy ? _c("ResultNotFound") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }